<template>
  <div class="lu_comic_pc">
    <div class="slider-item" :style="{backgroundImage: 'url('+image.url+')'}" v-for="image in images" :key="image.id">
      <div class="content">
        <div class="title_show">
          <cjImg class="img_contain img_radius logo" :src="$getImgUrl(imgAddr, appInfo.logoUrl)" />
          <div class="info_show">
            <span class="app_name">{{ appInfo.appName }}</span>
            <span class="app_info">{{ appInfo.appDesc }}</span>
          </div>
        </div>
        <img class="img_contain" style="height: 80%;margin-top: 10%" :src="image.url2" alt="手机预览">
      </div>
      <div class="downlaod">
        <!-- 暂无二维码 -->
        <!--      <div class="ewm" id="qrcode" ref="qrcode"></div>-->
        <div class="button_group">
          <img v-if="type==='Android'" class="img_contain down_button" @click="download('Android')" style="width: 80%;" :src="require('../../../assets/downlaod_button.png')" alt="">
          <img v-if="type==='IOS'" class="img_contain down_button" @click="download('IOS')" style="width: 80%;" :src="require('../../../assets/downlaod_button.png')" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mobileDetect from 'mobile-detect'
import { getChannelCode } from '@/utils/checkAgent'
import { queryDownloadInfo } from "@/api/home";
import cjImg from "@/components/cjImg";
import { base64Encode } from "@/utils/strUtil";
import { lightPackege } from "@/utils/common";
import { addLiquidId, clickReport, getIP, isValidBrowser } from '@/utils/download'

export default {
  name: "hulu-H5",
  components: { cjImg },
  data() {
    return {
      show: false,
      images: [],
      Interval: null,
      imgAddr: '',
      appInfo: '',
      type: '',
      aParams: ['', '', '', '']
    }
  },
  created() {
    this.type = this.isAndroid()
    this.aParams = getChannelCode(this.$router.currentRoute.query.a)
    this.images = [
      { id: 1, url: require('../../../assets/H5/bg.png'), url2: require('../../../assets/H5/bg_1.png') }
    ]
    this.innitChannelInfo()
    this.getChannelInfo()
  },
  watch: {},
  methods: {
    async innitChannelInfo() {
      const md = new mobileDetect(u)
      let ver = ''
      if (md.os() == 'iOS') {
        //ios系统的处理
        ver = md.versionStr('iPhone')
      } else if (md.os() == 'AndroidOS') {
        //Android系统的处理
        ver = md.versionStr('Android')
      }
      var u = navigator.userAgent
      let deviceInfo = {}
      deviceInfo.isAndroid = !!(u.indexOf('Android') > -1 || u.indexOf('Adr') > -1) //android终端
      deviceInfo.isiOS = !!u.match(/(iPhone|iPod|ios|iPad)/i) //ios终端
      deviceInfo.issafariBrowser = /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)
      deviceInfo.isMobile = deviceInfo.isAndroid || deviceInfo.isiOS
      deviceInfo.isValidBrowser = isValidBrowser()
      deviceInfo.packageCode = this.aParams[1] || process.env.VUE_APP_CHANNEL_CODE
      deviceInfo.invitationCode = this.aParams[2] || ''
      deviceInfo.appId = this.aParams[3] || process.env.VUE_APP_APP_ID
      window.deviceInfo = deviceInfo
      let that = this
      let clickId = that.$router.currentRoute.query.clickid
      console.log(that.$router.currentRoute, 'that.$router', window.location.hash.split('?')[1])
      getIP( async function (ip, area) {
        const params = {
          appId: deviceInfo.appId,
          channelCode: deviceInfo.packageCode,
          invitationCode: deviceInfo.invitationCode,
          clickId:clickId,
          deviceInfo: {
            ip: ip || '',
            // localIp: ,
            osType: md.os() == 'iOS' ? 1 : 0,
            osVersion: ver.replace(/\_/g, '.'),
            deviceWidth: window.screen.width,
            deviceHeight: window.screen.height,
            deviceRatio: window.devicePixelRatio
          },
          systemType: 1
        }
        await addLiquidId(params)
        await that.getChannelInfo()
        // sessionReport({ ip })
      })
    },
    getChannelInfo() {
      let params = {"data":{"packageCode":this.aParams[1] || process.env.VUE_APP_CHANNEL_CODE}}
      queryDownloadInfo(params).then(res =>{
        console.log(res);
        this.appInfo = res.data
        this.imgAddr = res.imgAddr
        document.title = res.data.appName
        var link = document.querySelector("link[rel*='icon']")
        window.fetchAndDec(this.appInfo.logoUrl)
            .then((res) => {
              link.href= `data:image/jpg;base64,${res}`
            })
            .catch((err) => {})
        // 暂无二维码
        // let origin = window.location.origin
        // let pack = ''
        // if (process.env.CHANNEL_CODE === undefined) {
        //   pack = 'gnuyz2'
        // }else {
        //   pack = process.env.CHANNEL_CODE
        // }
        // let url = origin + '/#/downLoad?pack='+pack
        // this.$nextTick(() =>{
        //   new QRCode('qrcode', {
        //     render: 'canvas',
        //     width: '128',
        //     height: '128',
        //     text: url,
        //   })
        // })
      }).catch(()=>{})
    },
    download(type) {
      let link = document.createElement('a');
      link.download = '';
      let appId = process.env.VUE_APP_APP_ID
      let appName = this.appInfo.appName
      // 是否为海外版轻量包，轻量包域名
      let { foreign, prefix } = this.appInfo
      // a参数 base64加密
      let parameter_a = base64Encode('##' + (this.aParams[1]||process.env.VUE_APP_CHANNEL_CODE) + '####' + (this.aParams[3]||appId) + '##')
      // 解析a参数，拼接轻量包类型数据
      let aStr = window.atob(parameter_a) + foreign + '##'
      let endecodeA = window.btoa(aStr)
      let clickid = this.$router.currentRoute.query?.clickid||''
      let a = encodeURIComponent(`${appId}$${appName}$${endecodeA}$${prefix}$${clickid}`)
      let url = `https://jack2030.gitlab.io/pool/${process.env.VUE_APP_ENV}/web.html?a=${a}`
      switch (type) {
        case 'Android':
          link.href = this.appInfo.infoList[1].shopDownloadUrl;
          link.download = appName
          clickReport({
            appId: this.aParams[3] || process.env.VUE_APP_APP_ID,
            channelCode: this.aParams[1] || process.env.VUE_APP_CHANNEL_CODE,
            type: 2 //类型 1-在线观看点击量 2-apk点击量 3-轻量包点击量
          }, (res) =>{
            console.log('link.click()',res);
            link.click()
          })
          break
        case 'IOS':
          window.fetchAndDec(this.appInfo.logoUrl).then((icon_src) => {
            // 16位编码数据（相同应用数值相同，不同应用数值不一样）
            let appIdStr = '33BC3CC7-18CE-4F97-9CF7-A24B608A212E'
            // 用渠道code进行替换
            let channelCode = aStr.split('##')[1].toUpperCase()
            appIdStr = appIdStr.replace(appIdStr.substring(appIdStr.length - channelCode.length), channelCode)
            // console.log('加密后的a参数----', appIdStr);
            // lightPackege(icon_src, url, appName, getUUID(), foreign)
            clickReport({
                appId: this.aParams[3] || process.env.VUE_APP_APP_ID,
                channelCode: this.aParams[1] || process.env.VUE_APP_CHANNEL_CODE,
                type: 3 //类型 1-在线观看点击量 2-apk点击量 3-轻量包点击量
              }, () =>{
                lightPackege(icon_src, url, appName, appIdStr)
              })
          }).catch(err=>{})
          break
      }
    },
    isAndroid() {
      let e =navigator.userAgent.toLowerCase()
      let n = /Android/i.test(e)
      let t = /iPhone|iPad|iPod/i.test(e);
      if (n){
        return 'Android'
      }
      if (t){
        return 'IOS'
      }
      return 'Android'
    }
  },
  beforeDestroy() {
    if (this.Interval !== null) {
      clearInterval(this.Interval)
      this.Interval = null
    }
  }
}
</script>

<style scoped lang="scss">
.lu_comic_pc {
  width: 100%;
  height: 100%;
  overflow: auto;
  .img_contain {
    object-fit: contain;
  }
  .down_button {
    cursor: pointer;
  }
  .img_radius {
    border-radius: 20%;
    border: 2px solid #fff;
  }
  .slider-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    //min-width: 375px;
    //min-height: 667px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .content {
      height: 85%;
      width: 100%;
      display: flex;
      flex-direction: column;
      //align-items: center;
      //justify-content: center;
      .title_show {
        height: 10%;
        width: 100%;
        display: flex;
        justify-content: start;
        .logo {
          margin-left: 3vw;
          margin-top: 3vw;
        }
        .info_show {
          margin-top: 2vw;
          display: flex;
          flex-direction: column;
          align-items: start;
          justify-content: center;
          margin-left: 3vw;
          .app_name {
            font-family: PingFangSC-Semibold;
            //font-size: 50px;
            font-weight: 600;
            font-size: 5vw;
            color: #FFFFFF;
            letter-spacing: 0;
            text-align: center;
            text-shadow: 0 2px 4px #E92A7A;
          }
          .app_info {
            margin-top: 5px;
            font-family: PingFangSC-Semibold;
            //font-size: 36px;
            font-weight: 600;
            font-size: 4vw;
            color: #FFFFFF;
            letter-spacing: 0;
            text-align: center;
            text-shadow: 0 2px 4px #E92A7A;
          }
        }
      }
    }
    .downlaod {
      height: 15%;
      //width: 100%;
      display: flex;
      align-items: start;
      justify-content: center;
      .ewm {
        //width: 30%;
        display: flex;
        justify-content: end;
        align-items: center;
        border: 1px solid #e5e5e6;
        padding: 8px;
        border-radius: 8px;
        background-color: #ffffff;
      }
      .button_group {
        //height: 100%;
        width: 80%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slider-buttons {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.slider-buttons button {
  margin: 0 10px;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #000;
  cursor: pointer;
}
</style>
<style>
/*.fade-enter,.fade-leave-to {*/
/*  opacity: 0;*/
/*}*/
/*.fade-enter-active,.fade-leave-active {*/
/*  transition: opacity 3s;*/
/*}*/
</style>